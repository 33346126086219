exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-test-2-js": () => import("./../../../src/pages/test2.js" /* webpackChunkName: "component---src-pages-test-2-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-contactus-js": () => import("./../../../src/templates/contactus.js" /* webpackChunkName: "component---src-templates-contactus-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-whatwedo-js": () => import("./../../../src/templates/whatwedo.js" /* webpackChunkName: "component---src-templates-whatwedo-js" */)
}

